import { encodeMaliciousString } from '../../common/request'

const updateParam = (searchParams, param, value) => {
  if (searchParams.get(param)) {
    searchParams.set(param, value)
  } else if (value) {
    searchParams.append(param, value)
  }
}

const updateParams = (searchParams, params) => {
  Object.keys(params).forEach(param => updateParam(searchParams, param, params[param]))
}

export const getXhrUrl = ({
  origin,
  format,
  lang,
  categoryCode,
  collectionId,
  ids,
  userId,
  searchParams,
  viewSize
}) => {
  if (searchParams.get('currentPage')) {
    mapParams('currentPage', 'page', searchParams)
  }

  const baseUrl = `${origin}/search-service/rest/v3/${format}/${lang}`

  updateParams(searchParams, {
    categoryCode,
    collectionId,
    viewSize,
    userId
  })

  if (collectionId) return `${baseUrl}/collection?${searchParams.toString()}`
  if (categoryCode) return `${baseUrl}/category?${searchParams.toString()}`
  if (ids) return `${baseUrl}/search/ids?${searchParams.toString()}`

  if (searchParams.get('text') !== null) {
    mapParams('text', 'term', searchParams)
  } else {
    searchParams.delete('text')
  }
  if (searchParams.get('term') !== null) {
    const encodedTerm = encodeMaliciousString(searchParams.get('term'))
    searchParams.delete('term')
    searchParams.set('term', encodeMaliciousString(encodedTerm))
  }
  return `${baseUrl}/search?${searchParams.toString()}`
}

export const getSuffix = (lang, format) => {
  if (format === 'praxis') return ''
  return lang.includes('fr') ? '/fr' : '/nl'
}

export const mapParams = (from, to, searchParams) => {
  const value = searchParams.get(from)
  searchParams.set(to, value)
  searchParams.delete(from)
}
