import React from 'react'
import Option from '../Option'

const SoftContentOptions = ({ softContentSuggestions = [], term, onSelect, selectedOption, t, startIndex = 0 }) => {
  return softContentSuggestions?.length ? (
    <>
      <div className='sfe-search-dropdown-list-subtitle-wrapper'>
        <div className='sfe-search-dropdown-list-subtitle'>{t('searchInput.advice')}</div>
      </div>
      {softContentSuggestions.map((option, i) => {
        return (
          <Option
            key={option.title}
            text={option.title}
            link={option.link}
            term={term}
            onSelect={onSelect}
            isSelected={startIndex + i === selectedOption}
            pageMode='content'
          />
        )
      })}
    </>
  ) : null
}

export default SoftContentOptions
