import { DESKTOP } from 'chemistry'

const PRODUCT_CODE_MAX_LENGTH = 18
export const PRODUCT_CODE_MIN_LENGTH = 7
export const BRICO = 'brico'

export const replaceInTranslation = (translation, replace = false) => {
  if (!replace) return translation
  Object.keys(replace).forEach(key => {
    const value = replace[key]
    const occ = new RegExp('{' + key + '}', 'g')
    if (translation) {
      translation = translation.replace(occ, value)
    }
  })
  return translation
}

export const leftPad = (number, length, character) => {
  character = character || '0'

  let myString = '' + number
  while (myString.length < length) {
    myString = character + myString
  }

  return myString
}

export const properProductCode = (code, full) => {
  let productCode = code && code.replace(/^0+/, '')

  if (productCode && productCode.length < PRODUCT_CODE_MIN_LENGTH) {
    productCode = leftPad(productCode, PRODUCT_CODE_MIN_LENGTH)
  }

  if (!full) return productCode
  return leftPad(productCode, PRODUCT_CODE_MAX_LENGTH)
}

export function getLocale(format, language) {
  if (format === BRICO) {
    if (language === 'nl' || language === 'nl_BE') {
      return 'nl_BE'
    } else {
      return 'fr_BE'
    }
  }
  return 'nl_NL'
}

export const removeSpecialChars = term => term.replace(/[^0-9a-zA-Z:,\s]+/g, '')

export const getJumperId = ({ addToPageJumper, shortTitlePageJumper }) => {
  if (!addToPageJumper) return false
  if (!shortTitlePageJumper) return false
  return shortTitlePageJumper
    .replace(/ /g, '-')
    .toLowerCase()
    .replace(/[^a-zàâçéèêëîïôûùüÿñæœ0-9\-_]/g, '')
}

export const getProductPosition = (position, page, perPage) => perPage * Math.max(page - 1, 0) + position

export const applyContainerSx = (props, data) => {
  props.sx = {
    ...props.sx
  }
  const { maxWidth } = data
  if (maxWidth) {
    props.sx = {
      ...props.sx,
      [DESKTOP]: {
        maxWidth: `${maxWidth}px`
      }
    }
  }
  return {
    ...props
  }
}

export const extractFnBody = fn => {
  return fn.toString().match(/function[^{]+\{([\s\S]*)}$/)[1]
}
