import React from 'react'
import { hydrate } from 'react-dom'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { getFragmentState } from './ssr-client'

export const initFragmentOnClient = (Fragment, fragmentName, id) => {
  const preloadedState = getFragmentState(id || fragmentName)
  const cache = createCache({ key: preloadedState.styleKey.toLowerCase() })
  const rootElement = document.getElementById(id || fragmentName)

  hydrate(
    <CacheProvider value={cache}>
      <Fragment {...preloadedState} />
    </CacheProvider>,
    rootElement
  )
}
