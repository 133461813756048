import { getLocale } from 'common/shopInfo'

export default function getProperties(request) {
  const { format, lang } = getRequestParams(request)
  const formula = format
  const language = lang
  return Object.freeze({ formula, language, locale: getLocale(format, lang) })
}

export const getPassedParams = req => {
  const systemKeys = [
    'categoryCode',
    'format',
    'lang',
    'ipAddress',
    'id',
    'modern',
    'pageType',
    'localeTypeHandler',
    'locale',
    'pageId',
    'assemblerId',
    'template',
    'type',
    'slug',
    'hasSeoData'
  ]

  const query = { ...req.query }

  const { contentfulPreview } = query
  if (!contentfulPreview) {
    systemKeys.push('contentfulPreview')
    systemKeys.push('env')
  }

  systemKeys.forEach(key => {
    if (query[key]) delete query[key]
  })

  const keys = Object.keys(query)

  if (!keys.length) return ''
  return `?${keys.map(k => k + '=' + query[k]).join('&')}`
}

export const getRequestParams = req => ({
  uri: req.headers['x-request-uri'],
  lang: req.headers['maxeda-lang'] || req.query.lang,
  format: req.headers['maxeda-format'] || req.query.format,
  referrer: req.headers.referer,
  passedParams: getPassedParams(req)
})

export const getIp = req => {
  const { ipAddress } = req.query
  const xForwardedFor = req.headers['x-forwarded-for']
  const result = ipAddress || xForwardedFor
  return `${result}`.split(',')[0]
}
export const isGuestUser = req => !getUserId(req)
export const getUserId = req => req?.cookies?.userid

export const encodeMaliciousString = userId => {
  if (!userId) return userId
  if (userId.includes('<script') || userId.includes('</script')) {
    return encodeURIComponent(userId)
  }
  return userId
}
