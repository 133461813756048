import React from 'react'
import Option from '../Option'
import SoftContentOptions from '../SoftContentOptions/SoftContentOptions'

const Options = ({ options, softContentSuggestions, term, selectedOption, onSelect, t }) => {
  return (
    <div className='sfe-search-dropdown sfe-search-dropdown-searching'>
      <div className='sfe-container'>
        <ul className='sfe-search-dropdown-list'>
          {options.map((option, i) => {
            return (
              <Option
                key={option.title}
                text={option.title}
                link={option.link}
                term={term}
                onSelect={onSelect}
                isSelected={i === selectedOption}
              />
            )
          })}
          <SoftContentOptions
            softContentSuggestions={softContentSuggestions}
            term={term}
            onSelect={onSelect}
            selectedOption={selectedOption}
            t={t}
            startIndex={options.length}
          />
        </ul>
      </div>
    </div>
  )
}

export default Options
