import React, { useRef, useState } from 'react'
import Options from '../Options/Options'
import SearchInput from '../SearchInput/SearchInput'
import classNames from 'classnames'

const DOWN_KEY = 40
const UP_KEY = 38
const ENTER = 13

const SearchInputContainer = ({
  format,
  lang,
  searchText,
  suggestions = [],
  softContentSuggestions = [],
  t,
  onSuggestionsChange,
  isInPage
}) => {
  const [focused, setFocused] = useState(false)
  const [term, setTerm] = useState(isInPage ? '' : searchText || '')
  const [searchPattern, setSearchPattern] = useState('')
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1)

  const root = useRef()
  const input = useRef()
  const form = useRef()
  const pageModeInput = useRef()

  const handleTermUpdate = value => {
    setTerm(value)
    setSearchPattern(value)
  }
  const fullClear = () => {
    setFocused(false)
    handleTermUpdate('')
    input.current?.blur()
  }

  const setPageMode = pageMode => {
    pageModeInput.current.value = pageMode

    if (!pageMode) {
      pageModeInput.current.name = ''
    }
  }

  const handleSubmit = (e, pageMode) => {
    if (!term) return
    e.preventDefault()
    window?.dataLayer?.push({ event: 'search', search_term: term })
    setPageMode(pageMode)
    form.current?.submit()
  }

  const handleKeyDown = e => {
    const resultsLength = suggestions.length + softContentSuggestions.length - 1
    const getTitle = index =>
      suggestions[index] ? suggestions[index].title : softContentSuggestions[index - suggestions.length].title
    if ([UP_KEY, DOWN_KEY].includes(e.keyCode) && resultsLength === -1) {
      e.preventDefault()
      return
    }

    switch (e.keyCode) {
      case DOWN_KEY: {
        const optionIndex = selectedOptionIndex === resultsLength ? 0 : selectedOptionIndex + 1
        setSelectedOptionIndex(optionIndex)
        setTerm(getTitle(optionIndex))
        break
      }
      case UP_KEY: {
        const optionIndex = selectedOptionIndex > 0 ? selectedOptionIndex - 1 : resultsLength
        setSelectedOptionIndex(optionIndex)
        setTerm(getTitle(optionIndex))
        break
      }
      case ENTER: {
        const pageMode = selectedOptionIndex >= suggestions.length && 'content'
        handleSubmit(e, pageMode)
        break
      }
    }
  }

  const handleSelect = (e, link, text, pageMode) => {
    e.preventDefault()
    if (link) {
      global.location.href = link
      return
    }
    setPageMode(pageMode)
    input.current.value = text

    form.current?.submit()
  }

  return (
    <div
      className={classNames({
        'sfe-search': true,
        'sfe-search-initial': !focused,
        'sfe-active': focused && (suggestions.length || softContentSuggestions.length)
      })}
      ref={root}
      onKeyDown={handleKeyDown}
    >
      <SearchInput
        inputRef={input}
        formRef={form}
        pageModeRef={pageModeInput}
        value={term}
        placeholder={t(`searchInput.${isInPage ? 'in_page_placeholder' : 'placeholder'}`)}
        name='text'
        focused={focused}
        lang={lang}
        format={format}
        onFocus={() => setFocused(true)}
        onClear={() => (term?.length > 0 ? setTerm('') : fullClear())}
        onBlur={() => setFocused(false)}
        onChange={event => {
          const termNew = event.target.value
          handleTermUpdate(termNew)
          setSelectedOptionIndex(-1)
          onSuggestionsChange(termNew)
        }}
        onClickSearchIcon={handleSubmit}
        t={t}
      />
      {focused && suggestions && (
        <Options
          options={suggestions}
          softContentSuggestions={softContentSuggestions}
          term={searchPattern}
          selectedOption={selectedOptionIndex}
          onSelect={handleSelect}
          t={t}
        />
      )}
    </div>
  )
}

export default SearchInputContainer
