import React, { useState } from 'react'
import { getSuffix } from 'client/common/url'
import { setCaretPosition, Cross, SearchIcon } from 'chemistry'

export const moveCursorToEnd = el => {
  el.focus()
  setCaretPosition(el, el.value.length)
}

const SearchInput = ({
  lang,
  format,
  value,
  placeholder,
  focused,
  inputRef,
  formRef,
  pageModeRef,
  onChange,
  onFocus,
  onBlur,
  onClear,
  onClickSearchIcon,
  t
}) => {
  const [initialOpen, setInitialOpen] = useState(false)
  const onClearHandler = e => {
    e.preventDefault()
    onClear?.()
  }

  const onFocusHandler = e => {
    onFocus(e)
    setInitialOpen(true)
  }

  const onClickHandler = e => {
    if (initialOpen) {
      moveCursorToEnd(inputRef.current)
      setInitialOpen(false)
    }
  }

  const actionLink = `${getSuffix(lang, format)}/search`
  return (
    <form ref={formRef} className='sfe-search-form' method='get' action={actionLink} autoComplete='off'>
      <div className='sfe-container'>
        <fieldset>
          <input
            id='searchInput'
            ref={inputRef}
            type='text'
            value={value}
            onChange={onChange}
            onFocus={onFocusHandler}
            onClick={onClickHandler}
            onBlur={onBlur}
            className='sfe-search-input'
            placeholder={placeholder}
            name='text'
            autoComplete='off'
          />
          {focused && (
            <div onMouseDown={onClearHandler} className='search-close' data-content={t('searchInput.cancel')}>
              <Cross color='ui-02-darkest' viewBox='0 0 24 24' width='24px' height='24px' />
            </div>
          )}
          <div onClick={e => onClickSearchIcon(e)} className='search-magnifier' data-content=''>
            <SearchIcon color='white' width='24px' height='24px' />
          </div>
          {/* input sets page-mode to soft-content search results */}
          <input ref={pageModeRef} id='pageModeInput' style={{ display: 'none' }} name='page-mode' />
        </fieldset>
      </div>
    </form>
  )
}

export default SearchInput
