import React from 'react'

export const getTranslator = dictionary => {
  return key => dictionary?.[key] || key
}
export const translateKey = (t, key) => t(key)
export const TranslateContext = React.createContext({})
export const TranslateContextProvider = ({ translations, children }) => {
  return <TranslateContext.Provider value={getTranslator(translations)}>{children}</TranslateContext.Provider>
}
