const throttle = () => {
  let timeout
  return (func, limit) => {
    clearTimeout(timeout)
    return new Promise(resolve => {
      timeout = setTimeout(() => resolve(func()), limit)
    })
  }
}

export default throttle()
